body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(rgb(79, 0, 96), rgb(8, 0, 55)); */
  background-color: #F9F9F9;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
}

.centerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bgGrey {
  background-color: #f9f9f9 !important;
}

.cursor-p {
  cursor: pointer !important;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-1 {
  margin-top: 1rem !important;
}

.py-0_5 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.text-orange {
  color: #F4511E !important;
}

.scrollbar::-webkit-scrollbar{
  height: 6px;
  width: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #f5411e;
  border-radius: 6px;
}


.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 23%); */
}

